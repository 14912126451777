import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-pa-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ParametroForm = _resolveComponent("ParametroForm")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ParametroForm, {
      onUpdate: _ctx.list,
      ref: "modalForm"
    }, null, 8, ["onUpdate"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_q_table, {
        title: "Parâmetros",
        "row-key": "id",
        rows: _ctx.rows,
        columns: _ctx.columns,
        filter: _ctx.filter,
        loading: _ctx.loading,
        pagination: { rowsPerPage: 10 }
      }, {
        top: _withCtx(() => [
          _createVNode(_component_q_btn, {
            label: "Novo Parâmetro",
            color: "primary",
            icon: "add",
            class: "q-mr-sm",
            onClick: _ctx.openModalForm
          }, null, 8, ["onClick"]),
          _createVNode(_component_q_btn, {
            label: "Voltar",
            color: "red",
            icon: "undo",
            to: "/area-redacao"
          }),
          _createVNode(_component_q_space),
          _createVNode(_component_q_input, {
            dense: "",
            debounce: "300",
            color: "primary",
            modelValue: _ctx.filter,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter) = $event))
          }, {
            append: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "search" })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        "body-cell-created_at": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatData(props.value.toString())), 1)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-actions": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                "no-caps": "",
                flat: "",
                dense: "",
                color: "primary",
                "icon-right": "edit",
                disable: _ctx.loading,
                onClick: ($event: any) => (_ctx.openModalForm(props.row))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_tooltip, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" Editar ")
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, 1032, ["disable", "onClick"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        _: 1
      }, 8, ["rows", "columns", "filter", "loading"])
    ])
  ]))
}